import { Fragment, useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { DASHBOARD_PAGE } from 'constants/translation-keys.ts';
import splitsRoute from 'pages/Splits/splits.route.tsx';
import fileUploadRoute from 'pages/FileUpload/file-upload.route.tsx';
import classificationRoute from 'pages/Classification/classification.route.tsx';
import redactionsRoute from 'pages/Redactions/redactions.route.tsx';
import hitlRoute from 'pages/HitL/hitl.route.tsx';
import claimsRoute from 'pages/Claims/claims.route.tsx';

import { ListSubheaderStyle } from './style.tsx';
import { NavListRoot, NavMiniRoot } from './NavItem';

interface NavListV2Props {
  isMiniNavbar?: boolean;
  removeDocuments: boolean;
}

type LinkProperty = {
  subheader: string;
  items: {
    title: string;
    shortTitle: string;
    path: string;
    icon: string;
    permission: string[];
  }[];
};

function NavListV2({ isMiniNavbar }: NavListV2Props) {
  const [state, dispatch] = useReducer(reducer, links);

  const { t } = useTranslationRoot(DASHBOARD_PAGE);

  // feature flags
  const isClaimsEnrichmentAvailable = useFeatureFlag('enableClaimsEnrichment');
  const isSplitsPageEnabled = useFeatureFlag('enableSplitsPage');
  const isClassificationPageEnabled = useFeatureFlag(
    'enableClassificationPage'
  );
  const isRedactionPageEnabled = useFeatureFlag('enableRedactionPage');

  useEffect(() => {
    if (!isClaimsEnrichmentAvailable) {
      dispatch(removeLinkAction(claimsRoute.path));
    }
    if (!isSplitsPageEnabled) {
      dispatch(removeLinkAction(splitsRoute.path));
    }
    if (!isClassificationPageEnabled) {
      dispatch(removeLinkAction(classificationRoute.path));
    }
    if (!isRedactionPageEnabled) {
      dispatch(removeLinkAction(hitlRoute.path));
    }
  }, [
    isClaimsEnrichmentAvailable,
    isClassificationPageEnabled,
    isRedactionPageEnabled,
    isSplitsPageEnabled,
  ]);

  const renderLargeLinks = ({ items, subheader }: LinkProperty) => (
    <Stack key={subheader} sx={{ width: 1 }}>
      <List key={subheader} disablePadding sx={{ width: 1 }}>
        {!!items.length && (
          <ListSubheaderStyle>{t(subheader)}</ListSubheaderStyle>
        )}

        {items?.map((list) => <NavListRoot key={list.title} list={list} />)}
      </List>
    </Stack>
  );

  const renderSmallLinks = ({ subheader, items }, index) => (
    <Fragment key={subheader}>
      {index !== 0 && items.length ? (
        <Box
          sx={{
            backgroundColor: 'grey.50024',
            mt: '12px !important',
            height: '1px',
            width: 24,
          }}
        />
      ) : null}
      {items.map((list) => (
        <NavMiniRoot key={list.title} list={list} />
      ))}
    </Fragment>
  );

  return (
    <Stack
      spacing={isMiniNavbar ? 0.5 : 0}
      sx={{
        alignItems: isMiniNavbar ? 'center' : 'flex-start',
        px: isMiniNavbar ? 0.5 : 0,
      }}
    >
      {isMiniNavbar
        ? navLinks(state).map(renderSmallLinks)
        : navLinks(state).map(renderLargeLinks)}
    </Stack>
  );
}

type State = {
  subheader: string;
  items: {
    name: string;
    route: typeof splitsRoute;
    search?: string;
  }[];
}[];

const links = [
  {
    subheader: 'navV2.tasks',
    items: [
      {
        name: 'fileSplit',
        route: splitsRoute,
        search: '',
      },
      {
        name: 'pageClassification',
        route: classificationRoute,
        search: '',
      },
      {
        name: 'pageRedaction',
        route: redactionsRoute,
        search: '',
      },
      {
        name: 'documentCorrection',
        route: hitlRoute,
        search: '?stage=AWAITING_REVIEW',
      },
      {
        name: 'claimReview',
        route: claimsRoute,
        search: '?stage=AWAITING_REVIEW',
      },
    ],
  },
  {
    subheader: 'navV2.clientPortal',
    items: [
      {
        name: 'fileUpload',
        route: fileUploadRoute,
      },
    ],
  },
] as State;

enum ActionType {
  REMOVE_LINK = 'REMOVE_LINK',
}

type Action = {
  type: ActionType.REMOVE_LINK;
  path: string;
};

function removeLinkAction(path: string): Action {
  return {
    type: ActionType.REMOVE_LINK,
    path,
  };
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.REMOVE_LINK:
      // remove object from items array if path matches action.path
      return state.map(({ subheader, items }) => ({
        subheader,
        items: items.filter(({ route }) => route.path !== action.path),
      }));
    default:
      return state;
  }
}

const navLinks = (links) =>
  links.map(({ items, subheader }) => ({
    items: items.map(({ name, route, search }) => ({
      title: `navV2.${name}`,
      shortTitle: `navShortV2.${name}`,
      path: route.createPath({ search }),
      icon: route.icon,
      permission: route.permission,
    })),
    subheader,
  }));

export { NavListV2 };
