import { createSelector } from 'reselect';
import type { DocumentApiResponse } from 'api/transformers/types/documents.ts';
import type {
  Document,
  DocumentContent,
  DocumentValidation,
} from 'types/Documents';
import {
  COMPLETED,
  ERROR,
  AWAITING_REVIEW,
  PROCESSING,
  REJECTED,
  REVIEW_IN_PROGRESS,
  CLOSED,
} from 'constants/document-stage';
import { CLAIM, DOCUMENT, FILE, LINE, PAGE } from 'constants/content-level';
import { not } from 'utils/not';
import { DICT } from 'constants/value-type';
import {
  transformDocumentResponse,
  transformDocumentsResponse,
  transformDocumentsTypesCountResponse,
  transformDocumentTypesResponse,
} from 'api/transformers/documents.ts';

import { selectState } from './utils.ts';

export const selectDocumentResponse = createSelector(
  selectState,
  transformDocumentResponse
);

export const selectDocumentsTypesResponse = createSelector(
  selectState,
  transformDocumentTypesResponse
);

export const selectDocumentTypes = createSelector(
  selectDocumentsTypesResponse,
  (response) => response.documentTypes
);

export const selectDocumentTypesCountResponse = createSelector(
  selectState,
  transformDocumentsTypesCountResponse
);

export const selectDocumentsResponse = createSelector(
  selectState,
  transformDocumentsResponse
);

export const selectDocumentsData = createSelector(
  selectDocumentsResponse,
  (response) => response.data
);

export const selectDocumentsTotal = createSelector(
  selectDocumentsResponse,
  (response) => response.total
);

export const selectDocumentsMetadataSortedColumns = createSelector(
  selectDocumentsResponse,
  (response) => response.metadata?.sortedColumns || []
);

export const selectDocumentTypesCountDocumentTypes = createSelector(
  selectDocumentTypesCountResponse,
  (response) => response.documentTypes
);

export function getDocumentStage(document: Document) {
  return document.stage;
}

const selectDocumentStage = createSelector(
  selectDocumentResponse,
  getDocumentStage
);

export const selectDocumentContent = createSelector(
  selectDocumentResponse,
  (document) => document.content
);

export function getDocumentLocked(document: Document) {
  return document.locked;
}

export function getDocumentReasonCode(document: Document) {
  return document.reasonCode;
}

export function getDocumentClientDocumentType(document: Document) {
  return document.clientDocumentType;
}

export function getDocumentType(document: Document) {
  return document.documentType;
}

export function getDocumentLastLockedBy(document: Document) {
  return document.lastLockedBy;
}

export function getDocumentRejectReason(document: Document) {
  return document.rejectReason;
}

export function getDocumentErrorMessage(document: Document) {
  return document.errorMessage;
}

export function getIsDocumentAwaitingReview(document: Document) {
  return getDocumentStage(document) === AWAITING_REVIEW;
}

export const selectIsDocumentAwaitingReview: (
  state: DocumentApiResponse
) => boolean = createSelector(
  selectDocumentStage,
  (stage) => stage === AWAITING_REVIEW
);

export function getIsDocumentErrored(document: Document) {
  return getDocumentStage(document) === ERROR;
}

export function getIsDocumentProcessing(document: Document) {
  return getDocumentStage(document) === PROCESSING;
}

export function getIsDocumentRejected(document: Document) {
  return getDocumentStage(document) === REJECTED;
}

export function getIsDocumentReviewInProgress(document: Document) {
  return getDocumentStage(document) === REVIEW_IN_PROGRESS;
}

export function getIsDocumentCompleted(document: Document) {
  return getDocumentStage(document) === COMPLETED;
}

export function getIsDocumentClosed(document: Document) {
  return getDocumentStage(document) === CLOSED;
}

function getDocumentContentLevel(content: DocumentContent) {
  return content.level;
}

function isClaimLevelItem(content: DocumentContent) {
  return getDocumentContentLevel(content) === CLAIM;
}

function isPageLevelItem(content: DocumentContent) {
  return getDocumentContentLevel(content) === PAGE;
}

function isFileLevelItem(content: DocumentContent) {
  return getDocumentContentLevel(content) === FILE;
}

export function isDocumentLevelItem(content: DocumentContent) {
  return getDocumentContentLevel(content) === DOCUMENT;
}

export function isHighLevelItem(content: DocumentContent) {
  return (
    isClaimLevelItem(content) ||
    isPageLevelItem(content) ||
    isFileLevelItem(content)
  );
}

export function isLineLevelItem(content: DocumentContent) {
  return getDocumentContentLevel(content) === LINE;
}

export function isDictValueTypeItem(content: DocumentContent) {
  return content.valueType === DICT;
}

export function isNotDictValueTypeItem(content: DocumentContent) {
  return not(isDictValueTypeItem)(content);
}

export const isNotLineLevelItem = not(isLineLevelItem);

function isContentBooleanType(content: DocumentContent) {
  return (
    typeof content.valid === 'boolean' && typeof content.valid !== 'object'
  );
}

/**
 * isValidItem and isNotValidItem is very specific as we have either
 * true, false or null value. null value items won't be validated by the IDP
 * so just leave it in Identified Items
 */
export function isValidItem(content: DocumentContent) {
  return isContentBooleanType(content) && content.valid;
}

export function isNotValidItem(content: DocumentContent) {
  return isContentBooleanType(content) && !content.valid;
}

export const selectDocumentHistorical = createSelector(
  selectDocumentResponse,
  (document) => document.historical
);

function getDocumentValidationStage(validation: DocumentValidation) {
  return validation.stage;
}

export function getIsDocumentValidationStageError(
  validation: DocumentValidation
) {
  return getDocumentValidationStage(validation) === ERROR;
}

export function getIsDocumentValidationStageCompleted(
  validation: DocumentValidation
) {
  return getDocumentValidationStage(validation) === COMPLETED;
}
