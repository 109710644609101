import type { Dispatch, SetStateAction } from 'react';
import IconButton from '@mui/material/IconButton';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { ARROW_FORWARD_ICON } from 'constants/public-icons.ts';
import { NAVBAR } from 'theme/layout.ts';

interface NavBarButtonProps {
  isMiniNavBarOpen: boolean;
  setLocalStore: Dispatch<SetStateAction<boolean>>;
  toggleMiniNavbar: VoidFunction;
}

function NavBarButton({
  isMiniNavBarOpen,
  setLocalStore,
  toggleMiniNavbar,
}: NavBarButtonProps) {
  const { t } = useTranslationRoot();

  return (
    <IconButton
      aria-label={t('common.miniNavbarLabel') as string}
      size="small"
      sx={{
        backgroundColor: 'background.default',
        border: '1px dashed',
        borderColor: 'grey.50024',
        position: 'fixed',
        left: isMiniNavBarOpen
          ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH - NAVBAR.TOGGLE / 2
          : NAVBAR.DASHBOARD_WIDTH - NAVBAR.TOGGLE / 2,
        top: 22,
        transform: isMiniNavBarOpen ? '' : 'rotate(180deg)',
        zIndex: ({ zIndex }) => zIndex.drawer,
      }}
      onClick={() => {
        toggleMiniNavbar();
        setLocalStore(!isMiniNavBarOpen);
      }}
    >
      <SvgIconStyle width={16} height={16} src={ARROW_FORWARD_ICON} />
    </IconButton>
  );
}

export { NavBarButton };
