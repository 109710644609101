import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { generalConfig } from 'config.ts';

import { Version } from './Version.tsx';
import { NavBarLogo } from './NavBarLogo.tsx';
import { NavListV2 } from './NavListV2.tsx';
import { AccountButtonV2 } from './AccountButtonV2.tsx';
import { LanguageButtonV2 } from './LanguageButtonV2.tsx';

function NavBarMini() {
  const { disableNavDocuments } = generalConfig;

  return (
    <Stack
      sx={{
        alignItems: 'center',
        height: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack>
        <NavBarLogo isMiniNavbar />

        <NavListV2 isMiniNavbar removeDocuments={disableNavDocuments} />
      </Stack>

      <Stack spacing={1} useFlexGap sx={{ alignItems: 'center' }}>
        <LanguageButtonV2 mini />
        <AccountButtonV2 mini />
        <Box
          sx={{
            py: 1,
          }}
        >
          <Version />
        </Box>
      </Stack>
    </Stack>
  );
}

export { NavBarMini };
